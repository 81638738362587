import { useState, useEffect } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { useNavigate, useOutletContext, useLocation } from 'react-router-dom';
import BaseForm from '../components/base-form';
import BaseList from '../components/base-list';
import Loading from '../components/loading';
import { getCalendars } from '../data/calendar';
import { canManageCalendars } from '../data/user';
import TopNav from '../components/top-nav';

const CalendarListRoute = () => {
    const me = useOutletContext();
    const navigate = useNavigate();
    const { state } = useLocation();
    const [message, setMessage] = useState(null);
    const [variant, setVariant] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const [calendars, setCalendars] = useState([]);
    const [items, setItems] = useState([]);
    const [filterOptions, setFilterOptions] = useState({
        categories: [],
        locations: []
    });
    const defaultState = {
        category: null,
        location: null,
        status: true
    };
    const savedState = JSON.parse(window.localStorage.getItem('calendarFilters'));
    const [filterState, setFilterState] = useState(savedState ? savedState : defaultState);

    const handleFilterChange = (newFilterState) => {
        setFilterState(newFilterState);
        window.localStorage.setItem('calendarFilters', JSON.stringify(newFilterState));
    };
    
    let customColumns = [
        {
            label: 'Action',
            render: (item) => {
                const link = '/admin' + (item.groupSession ? '/calendars/' + item.id + '/group-session-series' : '/booking' + '?id=' + item.id);
                const label = item.groupSession ? 'View Series' : 'View Appointments';
                return <Button className="w-100" onClick={() => navigate(link)}>{label}</Button>;
            }
        },
        {
            label: 'Action',
            render: (item) => {
                return <Button className="w-100" onClick={() => navigate('/admin/search', { state: item.id })}>Search Appointments</Button>;
            }
        }
    ];

    if (canManageCalendars(me)) {
        const managementActions = [
            {
                label: 'Action',
                render: (item) => {
                    return <Button className="w-100" onClick={() => navigate('/admin/calendars/' + item.id + '/dates')}>Edit Calendar Dates</Button>;
                }
            },
            {
                label: 'Action',
                render: (item) => {
                    return <Button className="w-100" onClick={() => navigate('/admin/calendars/' + item.id + '/users')}>Manage Access</Button>;
                }
            }
        ];
        customColumns = [...customColumns, ...managementActions];
    }

    const fields = [
        {
            name: 'name',
            label: 'Calendar Name',
            type: 'text'
        },
        {
            name: 'category',
            label: 'Category',
            type: 'text',
            postHook: (value) => {
                return value.name;
            }
        },
        {
            name: 'location',
            label: 'Location',
            type: 'text',
            postHook: (value) => {
                return value.name;
            }
        },
        {
            name: 'enabled',
            label: 'Enabled',
            type: 'checkbox'
        }
    ];

    const filterFields = [
        {
            name: 'category',
            label: 'Category',
            placeholder: 'Select a category',
            type: 'select',
            required: false,
            options: filterOptions.categories.map(x => {
                return {
                    label: x,
                    value: x
                };
            })
        },
        {
            name: 'location',
            label: 'Location',
            placeholder: 'Select a location',
            type: 'select',
            required: false,
            options: filterOptions.locations.map(x => {
                return {
                    label: x,
                    value: x
                };
            })
        },
        {
            name: 'status',
            label: 'Status',
            placeholder: 'Select a status',
            type: 'select',
            required: false,
            options: [
                {
                    label: 'Enabled',
                    value: true
                },
                {
                    label: 'Disabled',
                    value: false
                }
            ]
        }
    ];

    const handleEdit = (item) => {
        navigate('/admin/calendars/' + item['id'] + '/edit');
    };

    const handleCopy = (item) => {
        navigate('/admin/calendars/' + item['id'] + '/copy');
    };

    useEffect(() => {
        const params = {
            lanes: 0,
            category: 1,
            location: 1
        };

        if (!canManageCalendars(me)) {
            params.enabled = 1;
        }

        getCalendars(params).then(x => {
            if (x.items.length > 0) {
                setFilterOptions({
                    locations: [...new Set(x.items.map(x => x.location.name))],
                    categories: [...new Set(x.items.map(x => x.category.name))]
                });
                setCalendars(x.items);
            }
        }).catch(e => {
            setVariant(variant);
            setMessage(e.toString());
            setShowAlert(true);
        });

        if (state) {
            setMessage(state.message);
            setVariant(state.variant);
            setShowAlert(true);
            setTimeout(() => setShowAlert(false), 5000);
        }
    }, []);

    useEffect(() => {
        const isEnabled = filterState.status !== 'false';
        setItems(calendars.filter(x => {
            return (!filterState.location || x.location.name === filterState.location)
                && (!filterState.category || x.category.name === filterState.category)
                && (filterState.status === '' || x.enabled === isEnabled);
        }));
    }, [calendars, filterState]);

    if (!calendars) {
        return <Loading />;
    }

    return (
        <>
            <TopNav activeItem="Calendars" />
            <h1>Calendars</h1>
            <Alert show={showAlert} variant={variant}>{message}</Alert>
            <h2>Filters</h2>
            <BaseForm
                fields={filterFields}
                formState={filterState}
                setFormStateCallback={handleFilterChange}
                customSubmitElement={<></>}
            />
            <h2>Calendars</h2>
            <BaseList fields={fields} items={items}
                handleEdit={canManageCalendars(me) ? handleEdit : null}
                handleCopy={canManageCalendars(me) ? handleCopy : null}
                customColumns={filterState.status ? customColumns : []} />
            {canManageCalendars(me) ? <Button onClick={() => navigate('/admin/calendars/new')}>Add New Calendar</Button> : <></>}
        </>
    );
};

export default CalendarListRoute;

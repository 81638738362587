import { useState, useEffect } from 'react';
import { Alert, Button, Col, Modal, Row, Table } from 'react-bootstrap';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { getDashboard } from '../data/dashboard';
import { formatCalendarTitle } from '../data/calendar';
import { convertToISO8601, formatTime, formatDate, MONTH_NAMES, WEEKDAYS } from '../data/utils';
import { canManageCalendars } from '../data/user';
import Capacity from '../components/capacity';
import Loading from '../components/loading';
import TopNav from '../components/top-nav';

const DashboardRoute = () => {
    const me = useOutletContext();
    const navigate = useNavigate();
    const currentDate = new Date();
    const currentIsoDate = convertToISO8601(currentDate);
    const [filter, setFilter] = useState({
        year: currentDate.getFullYear(),
        month: currentDate.getMonth(),
        categoryId: null,
        locationId: null
    });
    const [message, setMessage] = useState(null);
    const [variant, setVariant] = useState(null);
    const [showMessage, setShowMessage] = useState(false);
    const [calendarDates, setCalendarDates] = useState([]);
    const [modalData, setModalData] = useState(null);

    const renderDay = (day) => {
        if (!day.items.length) {
            return <span>{day.label}</span>;
        }

        return (
            <div>
                <span>{day.label}</span>
                <div className="d-grid gap-1 mt-2">
                    {day.items.map((x, i) => {
                        return (
                            <Button
                                key={i} variant="primary" size="sm"
                                style={{ backgroundColor: '#' + x.calendar.location.colour, borderColor: '#' + x.calendar.location.colour }}
                                onClick={() => setModalData(x)}>
                                {formatCalendarTitle(x.calendar)} <br />
                                {formatTime(x.minStartTime) + ' - ' + formatTime(x.maxEndTime)}<br />
                            </Button>
                        );
                    })}
                </div>
            </div>
        );
    };

    const renderCalendar = () => {
        let days = [];
        let week = [];
        const firstDate = parseInt(new Date(filter.year, filter.month).getDay());
        const lastDate = new Date(filter.year, filter.month + 1, 0).getDate() + firstDate;

        for (let i = 1; i <= 42; i++) {
            let obj = false;
            if (i > firstDate && i <= lastDate) {
                const isoDate = new Date(filter.year, filter.month, i - firstDate).toISOString().split('T')[0];
                obj = {
                    date: isoDate,
                    label: parseInt(isoDate.split('-')[2]).toString(),
                    items: calendarDates.filter(x => {
                        return x.date == isoDate;
                    })
                };
            }
            week.push(obj);

            if (i % 7 === 0 || i === 42) {
                if (week.some(x => x)) {
                    days.push(week);
                }

                week = [];
            }
        }

        const handlePreviousMonthClick = () => {
            if (filter.month === 0) {
                setFilter({ month: 11, year: filter.year - 1 });
            } else {
                setFilter({ ...filter, month: filter.month - 1 });
            }
        };

        const handleNextMonthClick = () => {
            if (filter.month === 11) {
                setFilter({ month: 0, year: filter.year + 1 });
            } else {
                setFilter({ ...filter, month: filter.month + 1 });
            }
        };

        return (
            <Row>
                <Row>
                    <Col>
                        <Button className="float-end w-25" variant="secondary" onClick={handlePreviousMonthClick}>&laquo; Previous</Button>
                    </Col>
                    <Col xs="2">
                        <h4 className="text-center">{MONTH_NAMES[filter.month]} {filter.year}</h4>
                    </Col>
                    <Col>
                        <Button className="float-start w-25" variant="secondary" onClick={handleNextMonthClick}>Next &raquo;</Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Table responsive bordered size="sm" className="mt-4">
                            <thead>
                                <tr>
                                    {WEEKDAYS.map((x, i) => <th key={i}>{x}</th>)}
                                </tr>
                            </thead>
                            <tbody>
                                {days.map((week, index) =>
                                    <tr style={{ height: '9rem' }} key={index}>
                                        {week.map((day, i) => <td width="15%" className={day.date === currentIsoDate ? 'bg-info' : ''} key={i}>{day ? renderDay(day) : ''}</td>)}
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Row>
        );
    };

    const handleViewSeriesClick = (id, groupSessionSeriesId) => {
        const url = '/admin/calendars/' + id + '/group-session-series/' + groupSessionSeriesId;
        navigate(url);
    };

    const handleEditDateClick = (id, date) => {
        const url = '/admin/calendars/' + id + '/dates/' + date + '/edit';
        navigate(url);
    };

    const handleViewAppointmentsClick = (id, date) => {
        const params = {
            id: id,
            date: date
        };
        const urlParams = new window.URLSearchParams(params).toString();
        const url = '/admin/booking?' + urlParams;
        navigate(url);
    };

    const CalendarEventModal = () => {
        return (
            <Modal
                show={modalData !== null}
                onHide={() => setModalData(null)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {modalData.calendar.name} - {formatDate(modalData.date)}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>% Full</h4>
                    <Capacity value={modalData.capacity} />
                    <Table bordered className="mt-3">
                        <tbody>
                            <tr>
                                <th>Location</th>
                                <td>{modalData.calendar.location.name}</td>
                            </tr>
                            <tr>
                                <th>Start Time</th>
                                <td>{formatTime(modalData.minStartTime)}</td>
                            </tr>
                            <tr>
                                <th>End Time</th>
                                <td>{formatTime(modalData.maxEndTime)}</td>
                            </tr>
                            {modalData.calendar.groupSession ?
                                <>
                                    <tr>
                                        <th>Total Number of Spots</th>
                                        <td>{modalData.spots}</td>
                                    </tr>
                                    <tr>
                                        <th>Spots Available</th>
                                        <td>{modalData.spotsAvailable}</td>
                                    </tr>
                                    <tr>
                                        <th>Registrations</th>
                                        <td>{modalData.registrationCount}</td>
                                    </tr>
                                </>
                                :
                                <>
                                    <tr>
                                        <th>First Appointment</th>
                                        <td>{modalData.firstAppointmentTime ? formatTime(modalData.firstAppointmentTime) : 'N/A'}</td>
                                    </tr>
                                    <tr>
                                        <th>Last Appointment</th>
                                        <td>{modalData.lastAppointmentTime ? formatTime(modalData.lastAppointmentTime) : 'N/A'}</td>
                                    </tr>
                                    <tr>
                                        <th>Total Number of Clients</th>
                                        <td>{modalData.clientCount}</td>
                                    </tr>
                                    <tr>
                                        <th>Total Number of Booked Slots</th>
                                        <td>{modalData.bookedSlotCount}</td>
                                    </tr>
                                    <tr>
                                        <th>Total Number of Slots</th>
                                        <td>{modalData.totalSlotCount}</td>
                                    </tr>
                                    <tr>
                                        <th>Staff</th>
                                        <td>{modalData.laneCount}</td>
                                    </tr>
                                </>
                            }
                        </tbody>
                    </Table>
                </Modal.Body>
              <Modal.Footer>
                {canManageCalendars(me) && modalData.calendar.groupSession ? <Button className="float-start" onClick={() => handleViewSeriesClick(modalData.calendar.id, modalData.groupSessionSeriesId)}>View Registrations</Button> : ''}
                    {canManageCalendars(me) && !modalData.calendar.groupSession ? <Button className="float-start" onClick={() => handleEditDateClick(modalData.calendar.id, modalData.date)}>Edit Calendar Date</Button> : ''}
                    {modalData.hasAccess && !modalData.calendar.groupSession ? <Button className="float-start" onClick={() => handleViewAppointmentsClick(modalData.calendar.id, modalData.date)}>View Appointments</Button> : ''}
                    <Button className="float-end" onClick={() => setModalData(null)}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    };

    useEffect(() => {
        const firstDate = new Date(filter.year, filter.month);
        const lastDate = new Date(filter.year, filter.month + 1);

        getDashboard(firstDate, lastDate).then(res => {
            setCalendarDates(res.items);
        }).catch(e => {
            setVariant('danger');
            setMessage(e.toString());
            setShowMessage(true);
        });
    }, [filter]);

    if (!calendarDates) {
        return <Loading />;
    }

    return (
        <>
            <TopNav />
            <h1>Calendar Overview</h1>
            <Alert show={showMessage} variant={variant}>{message}</Alert>
            {renderCalendar()}
            {modalData ? <CalendarEventModal /> : ''}
        </>
    );
};

export default DashboardRoute;

import { sendRequest } from './api';
import { convertToISO8601 } from './utils';
const BASE_URL = 'dashboard';

export const getDashboard = (startDate, endDate) => {
    const params = {
        startDate: convertToISO8601(startDate),
        endDate: convertToISO8601(endDate)
    };
    const url = BASE_URL + '?' + new URLSearchParams(params).toString();
    const response = sendRequest(url);

    return response;
};

import { useEffect, useState } from 'react';
import { Alert, Button, Card, Col, Modal, Row, Tab, Table, Tabs } from 'react-bootstrap';
import { useOutletContext } from "react-router-dom";
import CalendarDates from '../components/calendar-dates';
import {
    getCalendars,
    getCalendarDates,
    getCalendarDate,
    formatCalendarTitle
} from '../data/calendar';
import {
    getAppointmentFields,
    getDefaultAppointmentState,
    createAppointment,
    updateAppointment,
    deleteAppointment,
    getAppointment,
    getGroupEditFields
} from '../data/appointment';
import { createLock } from '../data/lock';
import { deleteLock } from '../data/public';
import {
    convertTimeToSeconds,
    convertSecondsToTime,
    formatDate,
    formatTime,
    formatPhone
} from '../data/utils';
import { canManageCalendars } from '../data/user';
import Loading from '../components/loading';
import BaseForm from '../components/base-form';
import TopNav from '../components/top-nav';
import FormField from '../components/form-field';

const BookingManagerRoute = () => {
    const queryParams = new window.URLSearchParams(window.location.search);
    const dateParam = queryParams.get('date');
    const appointmentIdParam = queryParams.get('appointmentId');
    const groupEditFields = getGroupEditFields();
    const [appointmentId, setAppointmentId] = useState(null);
    const me = useOutletContext();
    const [id, setId] = useState(queryParams.get('id'));
    const [copyMode, setCopyMode] = useState(false);
    const [message, setMessage] = useState(null);
    const [variant, setVariant] = useState(null);
    const [showMessage, setShowMessage] = useState(false);
    const [calendar, setCalendar] = useState(null);
    const [calendars, setCalendars] = useState(null);
    const [calendarDates, setCalendarDates] = useState([]);
    const [calendarDate, setCalendarDate] = useState(null);
    const [showAppointmentForm, setShowAppointmentForm] = useState(false);
    const [availableDurations, setAvailableDurations] = useState([]);
    const [appointmentState, setAppointmentState] = useState(null);
    const [appointmentFields, setAppointmentFields] = useState([]);
    const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] = useState(false);
    const [appointmentIdToDelete, setAppointmentIdToDelete] = useState(null);
    const [readOnly, setReadOnly] = useState(false);
    const [groupEditState, setGroupEditState] = useState(null);
    const [history, setHistory] = useState([]);
    const [showHistoryDialog, setShowHistoryDialog] = useState(false);
    const currentDate = new Date();
    const currentDateISO = new Date().toISOString().split('T')[0];
    const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth());
    const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 12);
    const handleAppointmentFormShow = () => setShowAppointmentForm(true);
    const handleAppointmentFormClose = () => {
        if (appointmentState.lockToken) {
            handleDeleteLock(appointmentState.lockToken);
        } else {
            if (readOnly) {
                setReadOnly(false);
            }

            setShowAppointmentForm(false);
            setAppointmentState(null);
        }
    };

    const handleDeleteLock = (token) => {
        deleteLock(token).then(() => {
            if (!appointmentState) {
                displayMessage('The lock has been released.');
            }
        }).catch(e => {
            displayMessage('The lock token could not be deleted: ' + e.toString(), 'danger');
        }).finally(() => {
            if (appointmentState) {
                setShowAppointmentForm(false);
                setAppointmentState(null);
            } else {
                getCalendarDate(id, calendarDate.date, true).then(data => {
                    setCalendarDate(data);
                }).catch(e => {
                    displayMessage(e.toString(), 'danger');
                });
            }
        });
    };

    const handleDeleteConfirmationDialogClose = () => {
        setShowDeleteConfirmationDialog(false);
        setAppointmentIdToDelete(null);
    };

    const handleHistoryDialogClose = () => {
        setShowHistoryDialog(false);
        setHistory([]);
    };

    const displayMessage = (message = '', variant = 'success', hide = true) => {
        setVariant(variant);
        setMessage(message);
        setShowMessage(true);

        if (hide) {
            setTimeout(() => {
                setVariant(null);
                setMessage(null);
                setShowMessage(false);
            }, 5000);
        }
    };

    const handleSelectedDate = (date) => {
        getCalendarDate(id, date, true).then(res => {
            window.history.replaceState("", "", '/admin/booking?id=' + id + '&date=' + date);
            setCalendarDate(res);
        }).catch(e => {
            displayMessage(e.toString(), 'danger');
        });
    };

    const handleNewAction = (calendarDateLane, timeSlot) => {
        getCalendarDate(id, calendarDate.date, true).then(calendarDateRes => {
            setCalendarDate(calendarDateRes);
            const lane = calendarDateRes.lanes.find(thisLane => thisLane.id === calendarDateLane.id);
            const refreshedSlot = lane.timeslots.find(current => current.time === timeSlot.time);

            if (typeof refreshedSlot.availableDurations === 'undefined' || refreshedSlot.availableDurations.length === 0) {
                throw 'The time slot is currently unavailable.';
            }

            const availableDurations = lane.durations.filter(item => refreshedSlot.availableDurations.find(duration => duration === item.value));
            const defaultDuration = availableDurations.find(x => x.isDefault);
            const longestDuration = [...availableDurations].sort((a, b) => b - a).pop().value;

            createLock(timeSlot.time, lane.id, longestDuration).then(lockRes => {
                if (!lockRes.token) {
                    throw 'The server did not return a valid token.';
                }
                setAvailableDurations(availableDurations);
                const newAppointmentState = getDefaultAppointmentState(
                    lane,
                    convertTimeToSeconds(timeSlot.time),
                    defaultDuration ? defaultDuration.value : longestDuration,
                    lockRes.token,
                    calendar
                );
                setAppointmentState(newAppointmentState);
                handleAppointmentFormShow();
            }).catch(e => {
                displayMessage('Cannot initialize lock: ' + e.toString(), 'danger');
            });
        }).catch(e => {
            displayMessage('Cannot fetch calendar date: ' + e.toString(), 'danger');
        });
    };

    const handlePasteAction = (calendarDateLane, timeSlot) => {
        getCalendarDate(id, calendarDate.date, true).then(data => {
            setCalendarDate(data);
            const lane = data.lanes.find(thisLane => thisLane.id === calendarDateLane.id);
            const refreshedSlot = lane.timeslots.find(current => current.time === timeSlot.time);

            if (typeof refreshedSlot.availableDurations === 'undefined' || refreshedSlot.availableDurations.length === 0) {
                throw 'The time slot is currently unavailable.';
            }
            const startTime = convertTimeToSeconds(timeSlot.time);
            const availableDurations = lane.durations.filter(item => refreshedSlot.availableDurations.find(duration => duration === item.value));
            const longestDuration = [...availableDurations].sort((a, b) => b - a).pop().value;

            createLock(timeSlot.time, lane.id, longestDuration).then(lockRes => {
                if (!lockRes.token) {
                    throw 'The server did not return a valid token.';
                }
                const durationFound = availableDurations.find(x => x.value === appointmentState.duration);
                const newDuration = durationFound ? durationFound.value : longestDuration;
                const newAppointmentState = {
                    calendarDateLaneId: lane.id,
                    startTime: startTime,
                    endTime: startTime + newDuration,
                    duration: newDuration,
                    lockToken: lockRes.token
                };
                setAvailableDurations(availableDurations);
                setAppointmentState({ ...appointmentState, ...newAppointmentState });
                handleAppointmentFormShow();
            }).catch(e => {
                displayMessage('Cannot initialize lock: ' + e.toString(), 'danger');
            });
        }).catch(e => {
            displayMessage(e.toString(), 'danger');
        });
    };

    function handleViewAction(lane, timeSlot) {
        getAppointment(timeSlot.appointmentId).then(res => {
            const newAppointmentState = {
                ...res,
                startTime: convertTimeToSeconds(res.startTime),
                endTime: convertTimeToSeconds(res.endTime),
                duration: convertTimeToSeconds(res.endTime) - convertTimeToSeconds(res.startTime)
            };

            const availableDurations = lane.durations.filter(item => item.value === newAppointmentState.duration);
            setAvailableDurations(availableDurations);
            setAppointmentState(newAppointmentState);
            setReadOnly(true);
            handleAppointmentFormShow();
        }).catch(e => {
            displayMessage(e.toString(), 'danger');
        });
    }

    const handleEditAction = (calendarDateLane, timeSlot) => {
        getCalendarDate(id, calendarDate.date, true).then(data => {
            setCalendarDate(data);
            const lane = data.lanes.find(thisLane => thisLane.id === calendarDateLane.id);
            const refreshedSlot = lane.timeslots.find(current => current.time === timeSlot.time);

            if (typeof refreshedSlot.availableDurations === 'undefined' || refreshedSlot.availableDurations.length === 0) {
                throw 'The time slot is currently unavailable.';
            }

            getAppointment(refreshedSlot.appointmentId).then(res => {
                const availableDurations = lane.durations.filter(item => refreshedSlot.availableDurations.find(duration => duration === item.value));
                const longestDuration = [...availableDurations].sort((a, b) => b - a).pop().value;
                createLock(refreshedSlot.time, lane.id, longestDuration).then(lockRes => {
                    if (!lockRes.token) {
                        throw 'The server did not return a valid token.';
                    }
                    setAvailableDurations(availableDurations);
                    const newAppointmentState = {
                        ...res,
                        startTime: convertTimeToSeconds(res.startTime),
                        endTime: convertTimeToSeconds(res.endTime),
                        duration: convertTimeToSeconds(res.endTime) - convertTimeToSeconds(res.startTime),
                        sendConfirmationEmail: false,
                        lockToken: lockRes.token
                    };
                    if (newAppointmentState.multipleClients) {
                        const newGroupEditState = groupEditFields.map(x => x.name).reduce((x, y) => {
                            const defaultValue = newAppointmentState.clientData.every((v, i, a) => i === 0 || v[y] === a[i - 1][y])
                                ? newAppointmentState.clientData[0][y] : null;
                            return { ...x, [y]: defaultValue };
                        }, {});
                        setGroupEditState(newGroupEditState);
                    }
                    setAppointmentState(newAppointmentState);
                    handleAppointmentFormShow();
                }).catch(e => {
                    displayMessage('Cannot initialize lock: ' + e.toString(), 'danger');
                });
            }).catch(e => {
                displayMessage(e.toString(), 'danger');
            });
        }).catch(e => {
            displayMessage(e.toString(), 'danger');
        });
    };

    const handleDeleteAction = (appointmentId) => {
        setShowDeleteConfirmationDialog(true);
        setAppointmentIdToDelete(appointmentId);
    };

    const handleDeleteConfirmationAction = () => {
        deleteAppointment(appointmentIdToDelete).then(() => {
            getCalendarDate(id, calendarDate.date, true).then(res => {
                setCalendarDate(res);
                handleDeleteConfirmationDialogClose();
                displayMessage('The appointment has been deleted.', 'success');
            }).catch(e => {
                displayMessage(e.toString(), 'danger');
            });
        }).catch(e => {
            handleDeleteConfirmationDialogClose();
            displayMessage(e.toString(), 'danger');
        });
    };

    const handleCopyAction = (appointmentId) => {
        getAppointment(appointmentId).then(res => {
            const { id: _, ...copiedAppointment } = res;
            const newAppointment = {
                ...copiedAppointment,
                clientData: copiedAppointment.clientData.map(x => {
                    return {
                        ...x, ...{
                            attendance: null,
                            initials: null,
                            room: null
                        }
                    };
                })
            };
            setAppointmentState(newAppointment);
            setCopyMode(true);
        }).catch(e => {
            displayMessage(e.toString(), 'danger');
        });
    };

    const handleMoveAction = (appointmentId) => {

        getAppointment(appointmentId).then(res => {
            setAppointmentState(res);
            setCopyMode(true);
        }).catch(e => {
            displayMessage(e.toString(), 'danger');
        });
    };

    const handleCancelCopyMode = () => {
        setCopyMode(false);
        setAppointmentState(null);
    };

    useEffect(() => {
        const params = {
            lanes: 0,
            enabled: 1,
            location: 1,
            category: 1,
            excludeGroupSessions: 1
        };

        if (!canManageCalendars(me)) {
            params.enabled = 1;
        }

        getCalendars(params).then(x => {
            setCalendars(x.items);
        }).catch(e => {
            displayMessage(e.toString(), 'danger');
        });

        if (appointmentIdParam) {
            setAppointmentId(appointmentIdParam);
        }
    }, []);

    useEffect(() => {
        if (calendars) {
            setCalendar(calendars.find(x => x.id === parseInt(id)));
            setCalendarDate(null);
        }
    }, [id, calendars]);

    useEffect(() => {
        if (copyMode) {
            displayMessage('Select an available time slot.', 'info', false);
        } else {
            setShowMessage(false);
        }
    }, [copyMode]);

    useEffect(() => {
        getCalendarDates(id, startDate, endDate, true).then(res => {
            setCalendarDates(res.items);

            if (!calendarDate) {
                if (dateParam) {
                    handleSelectedDate(dateParam);
                } else if (res.items.length > 0) {
                    const firstDate = res.items.find((item) => {
                        return item.date >= currentDateISO;
                    });

                    if (firstDate) {
                        handleSelectedDate(firstDate.date);
                    }
                }
            } else {
                if (appointmentId) {
                    calendarDate.lanes.forEach(lane => {
                        lane.timeslots.forEach(slot => {
                            if (appointmentId == slot.appointmentId) {
                                if (res.date < currentDateISO) {
                                    handleViewAction(lane, slot);
                                } else {
                                    handleEditAction(lane, slot);
                                }
                            }
                        });
                    });
                    setAppointmentId(null);
                }
            }
        }).catch(e => {
            displayMessage(e.toString(), 'danger');
        });
    }, [calendarDate, id]);

    useEffect(() => {
        if (calendarDate) {
            getCalendarDate(id, calendarDate.date, true).then(data => {
                setCalendarDate(data);
            }).catch(e => {
                displayMessage(e.toString(), 'danger');
            });
        }

        if (!showAppointmentForm && copyMode) {
            handleCancelCopyMode();
        }
    }, [showAppointmentForm]);

    useEffect(() => {
        if (calendar && appointmentState) {
            const newAppointmentFields = getAppointmentFields(
                calendar,
                availableDurations,
                appointmentState
            );
            setAppointmentFields(newAppointmentFields);
        }
    }, [appointmentState]);

    const renderActionButtons = (lane, slot) => {
        if (calendarDate.date < currentDateISO) {
            return slot.appointmentId && !appointmentState ?
                <Row>
                    <Col xs={12} className="my-1">
                        <Button className="w-100" variant="primary" onClick={() => handleViewAction(lane, slot)}>View</Button>
                    </Col>
                    <Col xs={12} className="my-1">
                        <Button className="w-100" variant="primary" onClick={() => handleEditAction(lane, slot)}>Edit</Button>
                    </Col>
                    <Col xs={12} className="my-1">
                        <Button className="w-100" variant="primary" onClick={() => handleCopyAction(slot.appointmentId)}>Copy</Button>
                    </Col>
                </Row>
                :
                <></>;
        }

        return (
            <Row className="d-print-none">
                {!slot.appointmentId && slot.isBookable && !appointmentState ? <Col xs={12} className="my-1"><Button className="w-100" variant="primary" onClick={() => handleNewAction(lane, slot)}>New</Button></Col> : ''}
                {slot.appointmentId && !appointmentState ? <Col xs={6} className="my-1"><Button className="w-100" variant="primary" onClick={() => handleViewAction(lane, slot)}>View</Button></Col> : ''}
                {slot.appointmentId && !appointmentState ? <Col xs={6} className="my-1"><Button className="w-100" variant="primary" onClick={() => handleEditAction(lane, slot)}>Edit</Button></Col> : ''}
                {slot.appointmentId && !appointmentState ? <Col xs={6} className="my-1"><Button className="w-100" variant="primary" onClick={() => handleCopyAction(slot.appointmentId)}>Copy</Button></Col> : ''}
                {slot.appointmentId && !appointmentState ? <Col xs={6} className="my-1"><Button className="w-100" variant="primary" onClick={() => handleMoveAction(slot.appointmentId)}>Move</Button></Col> : ''}
                {slot.appointmentId && !appointmentState ? <Col xs={12} className="my-1"><Button className="w-100" variant="danger" onClick={() => handleDeleteAction(slot.appointmentId)}>Delete</Button></Col> : ''}
                {slot.isBookable && appointmentState ? <Col xs={6} className="my-1"><Button className="w-100" variant="primary" onClick={() => handlePasteAction(lane, slot)}>Select</Button></Col> : ''}
                {slot.token && !appointmentState ? <Col xs={12} className="my-1"><Button className="w-100" variant="warning" onClick={() => handleDeleteLock(slot.token)}>Unlock</Button></Col> : ''}
            </Row>
        );
    };

    const renderCalendarDate = () => {
        if (!calendarDate) {
            return <></>;
        }

        if (!calendarDate.lanes) {
            return (
                <Row>
                    <h3>{formatDate(calendarDate.date)}</h3>
                    <p>Calendar date lanes not configured. Please configure at least one lane.</p>
                </Row>
            );
        }

        const handleRefreshClick = () => {
            handleSelectedDate(calendarDate.date);
            displayMessage('The timeslots have been refreshed.', 'info');
        };

        const renderClients = (clients) => {
            return (
                <Table size="sm" bordered hover striped>
                    <thead>
                        <tr>
                          <th>Name</th>
                          <th>DOB</th>
                          <th>Attendance</th>
                          <th>Initials</th>
                          <th>Nurse/Room</th>
                        </tr>
                    </thead>
                    <tbody>
                      {clients.map((x, i) => {
                            let className = '';

                            switch (x.attendance) {
                                case 'LEFT':
                                    className = 'text-success';
                                    break;
                                case 'ARRIVED':
                                    className = 'text-warning';
                                    break;
                                case 'NOSHOW':
                                    className = 'text-danger';
                                    break;
                            }

                            return (
                                <tr key={i}>
                                  <td>{x.name}</td>
                                  <td>{x.dateOfBirth}</td>
                                  <td className={className}>
                                    <strong>{x.attendance}</strong>
                                  </td>
                                  <td>{x.initials}</td>
                                  <td>{x.room}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            );
        };

        const handleViewHistoryClick = (history) => {
            setHistory(history);
            setShowHistoryDialog(true);
        };

        return (
            <Row>
                <h3>{formatDate(calendarDate.date)}</h3>
                <Row>
                    <Col>
                        <Table striped bordered hover size="sm">
                            <thead>
                                <tr>
                                    <th width="25%">Capacity</th>
                                    <th width="25%">Appointments</th>
                                    <th width="25%">Clients</th>
                                    <th width="25%">Slots</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{calendarDate.capacity}% Full</td>
                                    <td>{calendarDate.appointmentCount}</td>
                                    <td>{calendarDate.clientCount}</td>
                                    <td>{calendarDate.bookedSlotCount}/{calendarDate.totalSlotCount}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <Row className="my-3 d-print-none">
                    <Col>
                        <Button variant="warning" size="lg" onClick={handleRefreshClick}>Refresh</Button>{' '}
                        {copyMode ? <Button size="lg" variant="danger" onClick={() => handleCancelCopyMode()}>Cancel</Button> : <></>}
                        <Button variant="secondary" size="lg" className="float-end pl-2" onClick={() => window.print()}>Print</Button>
                    </Col>
                </Row>
                <Row className="my-3 d-print-none">
                    <Col>
                        <Alert show={showMessage} variant={variant}>{message}</Alert>
                    </Col>
                </Row>
                <Row>
                    <Tabs justify defaultActiveKey="lane-0" className="d-print-none mb-3" justify>
                        {calendarDate.lanes.map((lane, index) => {
                            let lastAppointmentId = null;
                            return (
                                <Tab key={index} eventKey={"lane-" + index} title={lane.name}>
                                    <h3>{lane.name}</h3>
                                    <Table responsive striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th width="10%">Time</th>
                                                <th width="15%">Status</th>
                                                <th>Clients</th>
                                                <th width="10%">Phone</th>
                                                <th width="15%">Last Update</th>
                                                <th width="15%">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {lane.timeslots.map((slot, slotIndex) => {
                                                let showActions = true;
                                                if (slot.appointmentId) {
                                                    if (slot.appointmentId !== lastAppointmentId) {
                                                        lastAppointmentId = slot.appointmentId;
                                                    } else {
                                                        showActions = false;
                                                    }
                                                }
                                                return (
                                                    <tr key={slotIndex}>
                                                        <td>{formatTime(slot.time)}</td>
                                                        <td>
                                                            <span className={slot.label === 'Available' ? 'text-success' : 'text-danger'}>{slot.label}</span>
                                                        </td>
                                                        <td className="p-0">{showActions && slot.clients ? renderClients(slot.clients) : ''}</td>
                                                        <td>{showActions && slot.phone ? slot.phone.split(',').map(x => formatPhone(x)).join(' or ') : ''}</td>
                                                        <td>
                                                            {showActions && slot.history ?
                                                                <Row>
                                                                    <Col>{slot.history[0].updatedBy + ' on ' + new Date(slot.history[0].updatedAt).toLocaleString()}</Col>
                                                                </Row>
                                                                : ''}
                                                            {showActions && slot.history && slot.history.length > 1 && canManageCalendars(me) ?
                                                                <Row className="mt-4"><Col><Button onClick={() => handleViewHistoryClick(slot.history)}>View Changes</Button></Col></Row>
                                                                : ''}
                                                        </td>
                                                        <td>
                                                            {showActions ? renderActionButtons(lane, slot) : <></>}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </Table>
                                </Tab>
                            );
                        })}
                    </Tabs>
                </Row>
            </Row>
        );
    };

    if (!calendar || !calendars || !calendarDates) {
        return <Loading />;
    }

    const getFormTitle = () => {
        if (!calendarDate || !appointmentState) {
            return '';
        }

        let action = '';

        if (readOnly) {
            action = 'View';
        } else {
            if (appointmentState && appointmentState.id) {
                action = 'Edit';
            } else {
                action = 'New';
            }
        }

        let title = action + ' Appointment - ' + formatDate(calendarDate.date);
        title += ' ' + formatTime(convertSecondsToTime(appointmentState.startTime));

        return title;
    };

    const calendarField = {
        name: 'calendarId',
        type: 'select',
        options: calendars.map(x => {
            return {
                label: formatCalendarTitle(x),
                value: x.id
            };
        })
    };

    const handleCalendarChange = (x) => {
        window.history.replaceState("", "", '/admin/booking?id=' + id);
        setId(x);
    };

    const getAppointmentForm = () => {
        if (!appointmentState) {
            return '';
        }

        const handleAppointmentSaveAction = () => {
            const convertedTimes = {
                startTime: convertSecondsToTime(appointmentState.startTime),
                endTime: convertSecondsToTime(appointmentState.endTime)
            };
            const formData = { ...appointmentState, ...convertedTimes };
            let response = null;

            if (typeof formData.id !== 'undefined') {
                response = updateAppointment(formData);
            } else {
                response = createAppointment(formData);
            }
            setCopyMode(false);
            response.then(() => {
                handleAppointmentFormClose();
                getCalendarDate(id, calendarDate.date, true).then(res => {
                    setCalendarDate(res);
                    displayMessage('The appointment has been saved.');
                }).catch(e => {
                    displayMessage(e.toString(), 'danger');
                });
            }).catch((e) => {
                displayMessage('Cannot create the appointment: ' + e.toString(), 'danger');
                handleAppointmentFormClose();
            });
        };

        const getGroupEditForm = () => {
            if (
                !appointmentState.id
                || !appointmentState.multipleClients
                || readOnly
                || !groupEditState
            ) {
                return <></>;
            }

            const handleGroupEditStateChange = (data) => {
                const newState = {
                    ...appointmentState,
                    clientData: appointmentState.clientData.map(x => {
                        return { ...x, ...data };
                    })
                };
                setGroupEditState(data);
                setAppointmentState(newState);
            };

            return (
                <Card className="mb-3">
                    <Card.Header as="h2">Group Edit</Card.Header>
                    <Card.Body>
                        <p>This form allows you to edit an entire group at once.</p>
                        <BaseForm
                            fields={groupEditFields}
                            formState={groupEditState}
                            setFormStateCallback={handleGroupEditStateChange}
                            submitButtonLabel="Save Appointment"
                            validFormCallback={handleAppointmentSaveAction} />
                    </Card.Body>
                </Card>
            );
        };

        const handleAppointmentStateChange = (data) => {
            const endTime = parseInt(data.startTime) + parseInt(data.duration);
            const newAppointmentState = {
                ...data,
                endTime: endTime
            };
            setAppointmentState(newAppointmentState);
        };

        return (
            <>
                {getGroupEditForm()}
                <Card>
                    <Card.Header as="h2">Appointment Information</Card.Header>
                    <Card.Body className="appointment-form-container">
                        <BaseForm
                            fields={appointmentFields}
                            formState={appointmentState}
                            setFormStateCallback={handleAppointmentStateChange}
                            validFormCallback={handleAppointmentSaveAction}
                            submitButtonLabel="Save Appointment"
                            readOnly={readOnly} />
                    </Card.Body>
                </Card>
            </>
        );
    };

    const previousItems = [
        {
            label: 'Calendars',
            link: '/admin/calendars'
        }
    ];

    return (
        <>
            <Row className="d-print-none">
                <TopNav activeItem="Manage Appointments" previousItems={previousItems} />
                <h1>Manage Appointments</h1>
                <h2>Current Calendar</h2>
                <FormField
                    label="Select a calendar"
                    key="calendarId"
                    index="calendarId"
                    field={calendarField}
                    fieldValue={id}
                    readOnly={false}
                    setFieldValue={handleCalendarChange}
                />
            </Row>
            <Row>
                <Col xs={3} className="d-print-none">
                    <CalendarDates
                        startDate={startDate}
                        endDate={endDate}
                        calendarDates={calendarDates}
                        dateSelectedCallback={handleSelectedDate}
                        shortWeekDays={true}
                        selectedDate={calendarDate ? calendarDate.date : null}
                        baseUrl={"/admin/booking?id=" + id}
                    />
                </Col>
                <Col className="px-2 py-2">
                    <h2>{formatCalendarTitle(calendar)}</h2>
                    {renderCalendarDate()}
                </Col>
            </Row>
            <Modal
                show={showAppointmentForm}
                onHide={handleAppointmentFormClose}
                backdrop="static"
                keyboard={false}
                fullscreen={true}
                aria-labelledby="appointment-form-modal-title"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="appointment-form-modal-title">
                        <h1>{formatCalendarTitle(calendar)}</h1>
                        <h2>{getFormTitle()}</h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {getAppointmentForm()}
                </Modal.Body>
            </Modal>
            <Modal
                show={showHistoryDialog}
                onHide={handleHistoryDialogClose}
                backdrop="static"
                keyboard={false}
                aria-labelledby="history-modal-title"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="history-modal-title">
                        <h1>View Changes</h1>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Staff</th>
                                <th>Action</th>
                                <th>Time</th>
                            </tr>
                        </thead>
                        <tbody>
                            {history.map((record, i) =>
                                <tr key={i}>
                                    <td>{record.updatedBy}</td>
                                    <td>{record.action}</td>
                                    <td>{new Date(record.updatedAt).toLocaleString()}</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </Modal.Body>
            </Modal>
            <Modal
                show={showDeleteConfirmationDialog}
                onHide={handleDeleteConfirmationDialogClose}
                size="lg"
                aria-labelledby="delete-confirmation-modal-title"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="delete-confirmation-modal-title">
                        Deletion Confirmation
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Are you sure you want to delete this appointment?
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleDeleteConfirmationAction}>Yes</Button>
                    <Button onClick={handleDeleteConfirmationDialogClose}>Cancel</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default BookingManagerRoute;

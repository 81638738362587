import {
    Card,
    Form,
    Table
} from 'react-bootstrap';

const CalendarLaneDurations = ({ calendarLaneDurations = [], handleStateChange }) => {
    const appointmentDurations = [
        {
            value: 300,
            label: "5 minutes",
        },
        {
            value: 600,
            label: "10 minutes",
        },
        {
            value: 900,
            label: "15 minutes",
        },
        {
            value: 1800,
            label: "30 minutes",
        },
        {
            value: 2400,
            label: "40 minutes",
        },
        {
            value: 2700,
            label: "45 minutes",
        },
        {
            value: 3600,
            label: "1 hour",
        },
        {
            value: 7200,
            label: "2 hours",
        }
    ];

    const handleStatusChange = (duration, isChecked) => {
        const calendarLaneDuration = calendarLaneDurations.find(item => item.value === duration.value);

        if (typeof calendarLaneDuration === 'undefined' && isChecked) {
            const newCalendarLaneDurations = [...calendarLaneDurations, duration];
            handleStateChange(newCalendarLaneDurations);
        } else {
            const newCalendarLaneDurations = calendarLaneDurations.filter(item => item.value !== duration.value);
            handleStateChange(newCalendarLaneDurations);
        }
    };

    const handleDefaultChange = (duration, isChecked) => {
        const newDurations = calendarLaneDurations.map((current, index) => {
            return {
                ...current,
                isDefault: current.value === duration.value && isChecked || index === 0 && !isChecked
            };
        });
        handleStateChange(newDurations);
    };

    const handleLabelChange = (duration, newLabel) => {
        const calendarLaneDuration = calendarLaneDurations.find(item => item.value === duration.value);

        if (typeof calendarLaneDuration === 'undefined') {
            const newCalendarLaneDurations = [...calendarLaneDurations, { label: newLabel, value: duration.value }];
            handleStateChange(newCalendarLaneDurations);
        } else {
            const newCalendarLaneDurations = [
                ...calendarLaneDurations.filter(item => item !== calendarLaneDuration),
                { label: newLabel, value: duration.value }
            ];
            handleStateChange(newCalendarLaneDurations);
        }
    };

    return (
        <Card className="mb-3">
            <Card.Header>
                <h4 className="float-start pt-2">Durations</h4>
            </Card.Header>
            <Card.Body>
                <Table bordered responsive>
                    <thead>
                        <tr>
                            <th>Value</th>
                            <th>Label</th>
                            <th>Enabled</th>
                            <th>Default</th>
                        </tr>
                    </thead>
                    <tbody>
                        {appointmentDurations.map((appointmentDuration, index) => {
                            const calendarLaneDuration = calendarLaneDurations.find(item => item.value === appointmentDuration.value);
                            const label = calendarLaneDuration ? calendarLaneDuration.label : appointmentDuration.label;
                            const enabled = calendarLaneDuration ? true : false;
                            const isDefault = calendarLaneDuration && enabled === true && calendarLaneDuration.isDefault;

                            return (
                                <tr key={index}>
                                    <td>
                                        {parseInt(appointmentDuration.value) / 60} minutes
                                    </td>
                                    <td>
                                        <Form.Control type="text" required={true} placeholder="Enter label" value={label} onChange={(e) => handleLabelChange(appointmentDuration, e.target.value)} />
                                    </td>
                                    <td>
                                        <Form.Check type="switch" checked={enabled} onChange={(e) => handleStatusChange(appointmentDuration, e.target.checked)} />
                                    </td>
                                    <td>
                                        <Form.Check
                                            type="switch"
                                            checked={isDefault} onClick={(e) => enabled ? true : e.preventDefault()}
                                            onChange={(e) => handleDefaultChange(appointmentDuration, e.target.checked)}
                                        />
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    );
};

export default CalendarLaneDurations;

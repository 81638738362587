import { Card } from "react-bootstrap";

export default function Footer() {
    return (
        <footer className="footer">
            <Card>
                <Card.Header className="py-0">© EOHU 2024. All Rights Reserved. Version 1.21.0</Card.Header>
            </Card>
        </footer>
    );
}
